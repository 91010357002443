import { default as indexizZPLeyEnGMeta } from "/vercel/path0/.app/pages/dashboard/administracao/campanhas/index.vue?macro=true";
import { default as create1JLxYfhNYxMeta } from "/vercel/path0/.app/pages/dashboard/administracao/clients/create.vue?macro=true";
import { default as editPiMhx1Wrz6Meta } from "/vercel/path0/.app/pages/dashboard/administracao/clients/edit.vue?macro=true";
import { default as indexOKOxlnnNY5Meta } from "/vercel/path0/.app/pages/dashboard/administracao/clients/index.vue?macro=true";
import { default as indexSgTyp6IfUPMeta } from "/vercel/path0/.app/pages/dashboard/administracao/index.vue?macro=true";
import { default as avulsosyLMyIpHOIKMeta } from "/vercel/path0/.app/pages/dashboard/administracao/pagamentos/avulsos.vue?macro=true";
import { default as mensalistasHKYNIf3TzLMeta } from "/vercel/path0/.app/pages/dashboard/administracao/pagamentos/mensalistas.vue?macro=true";
import { default as comprar_45creditoKcONxWAfPrMeta } from "/vercel/path0/.app/pages/dashboard/comprar-credito.vue?macro=true";
import { default as indexhtazvs0kNcMeta } from "/vercel/path0/.app/pages/dashboard/d-whitelabel/index.vue?macro=true";
import { default as indexYSX8kKfq6GMeta } from "/vercel/path0/.app/pages/dashboard/index.vue?macro=true";
import { default as indicacoesBtjl9nUCPiMeta } from "/vercel/path0/.app/pages/dashboard/indicacoes.vue?macro=true";
import { default as createTCKzzl0SurMeta } from "/vercel/path0/.app/pages/dashboard/integration/create.vue?macro=true";
import { default as _91slug_93OtfMw68GuRMeta } from "/vercel/path0/.app/pages/dashboard/integration/detail/[slug].vue?macro=true";
import { default as editObqGbOFVtIMeta } from "/vercel/path0/.app/pages/dashboard/integration/edit.vue?macro=true";
import { default as funnel_45smsaYhSscMSe7Meta } from "/vercel/path0/.app/pages/dashboard/integration/funnel-sms.vue?macro=true";
import { default as meu_45planoNnmkrN4ENCMeta } from "/vercel/path0/.app/pages/dashboard/meu-plano.vue?macro=true";
import { default as FORMATARPlG9PiSpnZMeta } from "/vercel/path0/.app/pages/dashboard/modulos/FORMATAR.vue?macro=true";
import { default as gerador_45link_45detalhekPGbJa1qt7Meta } from "/vercel/path0/.app/pages/dashboard/modulos/gerador-link-detalhe.vue?macro=true";
import { default as JOGADORnwjSDUUv4RMeta } from "/vercel/path0/.app/pages/dashboard/modulos/JOGADOR.vue?macro=true";
import { default as LINKt5VywjcHvZMeta } from "/vercel/path0/.app/pages/dashboard/modulos/LINK.vue?macro=true";
import { default as configuracaowhi0IvVf3sMeta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/configuracao.vue?macro=true";
import { default as indexa0fYE4Uc8sMeta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/index.vue?macro=true";
import { default as revisargaoqBOyrK3Meta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/revisar.vue?macro=true";
import { default as criar_45smsLVbix5xfUBMeta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms.vue?macro=true";
import { default as indexAF6lBzu7UfMeta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/index.vue?macro=true";
import { default as _91id_93JBvMZt4VmuMeta } from "/vercel/path0/.app/pages/dashboard/modulos/SMS/relatorio/[id].vue?macro=true";
import { default as VALIDADORe86XI3zhplMeta } from "/vercel/path0/.app/pages/dashboard/modulos/VALIDADOR.vue?macro=true";
import { default as indexbjHvUPX1UNMeta } from "/vercel/path0/.app/pages/dashboard/perfil/configuracoes/index.vue?macro=true";
import { default as configuracoesOO5mAmMqZgMeta } from "/vercel/path0/.app/pages/dashboard/perfil/configuracoes.vue?macro=true";
import { default as plano_45inativouOC12fluBDMeta } from "/vercel/path0/.app/pages/dashboard/plano-inativo.vue?macro=true";
import { default as sairvobEaFEdqBMeta } from "/vercel/path0/.app/pages/dashboard/sair.vue?macro=true";
import { default as abrirycg3xRfAgyMeta } from "/vercel/path0/.app/pages/dashboard/ticket/abrir.vue?macro=true";
import { default as index6N8yvCkOrMMeta } from "/vercel/path0/.app/pages/dashboard/ticket/index.vue?macro=true";
import { default as _91_46_46_46id_935Pg5sXJpELMeta } from "/vercel/path0/.app/pages/dashboard/ticket/visualizar/[...id].vue?macro=true";
import { default as first_45access3B5cwQVg3AMeta } from "/vercel/path0/.app/pages/first-access.vue?macro=true";
import { default as indexJX2dp37VJQMeta } from "/vercel/path0/.app/pages/index.vue?macro=true";
import { default as register_45lZJwqOUkJaZMeta } from "/vercel/path0/.app/pages/register-l.vue?macro=true";
import { default as indexcWTEcKxExrMeta } from "/vercel/path0/.app/pages/register/index.vue?macro=true";
import { default as reviewErBuL7Yj0FMeta } from "/vercel/path0/.app/pages/register/review.vue?macro=true";
import { default as roleLxmrQ3JfWxMeta } from "/vercel/path0/.app/pages/register/role.vue?macro=true";
import { default as registerrhQHCmO9O7Meta } from "/vercel/path0/.app/pages/register.vue?macro=true";
import { default as smsAsMjt3uJqMMeta } from "/vercel/path0/.app/pages/sms.vue?macro=true";
export default [
  {
    name: indexizZPLeyEnGMeta?.name ?? "dashboard-administracao-campanhas",
    path: indexizZPLeyEnGMeta?.path ?? "/dashboard/administracao/campanhas",
    meta: indexizZPLeyEnGMeta || {},
    alias: indexizZPLeyEnGMeta?.alias || [],
    redirect: indexizZPLeyEnGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/campanhas/index.vue").then(m => m.default || m)
  },
  {
    name: create1JLxYfhNYxMeta?.name ?? "dashboard-administracao-clients-create",
    path: create1JLxYfhNYxMeta?.path ?? "/dashboard/administracao/clients/create",
    meta: create1JLxYfhNYxMeta || {},
    alias: create1JLxYfhNYxMeta?.alias || [],
    redirect: create1JLxYfhNYxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/clients/create.vue").then(m => m.default || m)
  },
  {
    name: editPiMhx1Wrz6Meta?.name ?? "dashboard-administracao-clients-edit",
    path: editPiMhx1Wrz6Meta?.path ?? "/dashboard/administracao/clients/edit",
    meta: editPiMhx1Wrz6Meta || {},
    alias: editPiMhx1Wrz6Meta?.alias || [],
    redirect: editPiMhx1Wrz6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/clients/edit.vue").then(m => m.default || m)
  },
  {
    name: indexOKOxlnnNY5Meta?.name ?? "dashboard-administracao-clients",
    path: indexOKOxlnnNY5Meta?.path ?? "/dashboard/administracao/clients",
    meta: indexOKOxlnnNY5Meta || {},
    alias: indexOKOxlnnNY5Meta?.alias || [],
    redirect: indexOKOxlnnNY5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgTyp6IfUPMeta?.name ?? "dashboard-administracao",
    path: indexSgTyp6IfUPMeta?.path ?? "/dashboard/administracao",
    meta: indexSgTyp6IfUPMeta || {},
    alias: indexSgTyp6IfUPMeta?.alias || [],
    redirect: indexSgTyp6IfUPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/index.vue").then(m => m.default || m)
  },
  {
    name: avulsosyLMyIpHOIKMeta?.name ?? "dashboard-administracao-pagamentos-avulsos",
    path: avulsosyLMyIpHOIKMeta?.path ?? "/dashboard/administracao/pagamentos/avulsos",
    meta: avulsosyLMyIpHOIKMeta || {},
    alias: avulsosyLMyIpHOIKMeta?.alias || [],
    redirect: avulsosyLMyIpHOIKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/pagamentos/avulsos.vue").then(m => m.default || m)
  },
  {
    name: mensalistasHKYNIf3TzLMeta?.name ?? "dashboard-administracao-pagamentos-mensalistas",
    path: mensalistasHKYNIf3TzLMeta?.path ?? "/dashboard/administracao/pagamentos/mensalistas",
    meta: mensalistasHKYNIf3TzLMeta || {},
    alias: mensalistasHKYNIf3TzLMeta?.alias || [],
    redirect: mensalistasHKYNIf3TzLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/administracao/pagamentos/mensalistas.vue").then(m => m.default || m)
  },
  {
    name: comprar_45creditoKcONxWAfPrMeta?.name ?? "dashboard-comprar-credito",
    path: comprar_45creditoKcONxWAfPrMeta?.path ?? "/dashboard/comprar-credito",
    meta: comprar_45creditoKcONxWAfPrMeta || {},
    alias: comprar_45creditoKcONxWAfPrMeta?.alias || [],
    redirect: comprar_45creditoKcONxWAfPrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/comprar-credito.vue").then(m => m.default || m)
  },
  {
    name: indexhtazvs0kNcMeta?.name ?? "dashboard-d-whitelabel",
    path: indexhtazvs0kNcMeta?.path ?? "/dashboard/d-whitelabel",
    meta: indexhtazvs0kNcMeta || {},
    alias: indexhtazvs0kNcMeta?.alias || [],
    redirect: indexhtazvs0kNcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/d-whitelabel/index.vue").then(m => m.default || m)
  },
  {
    name: indexYSX8kKfq6GMeta?.name ?? "dashboard",
    path: indexYSX8kKfq6GMeta?.path ?? "/dashboard",
    meta: indexYSX8kKfq6GMeta || {},
    alias: indexYSX8kKfq6GMeta?.alias || [],
    redirect: indexYSX8kKfq6GMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indicacoesBtjl9nUCPiMeta?.name ?? "dashboard-indicacoes",
    path: indicacoesBtjl9nUCPiMeta?.path ?? "/dashboard/indicacoes",
    meta: indicacoesBtjl9nUCPiMeta || {},
    alias: indicacoesBtjl9nUCPiMeta?.alias || [],
    redirect: indicacoesBtjl9nUCPiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/indicacoes.vue").then(m => m.default || m)
  },
  {
    name: createTCKzzl0SurMeta?.name ?? "dashboard-integration-create",
    path: createTCKzzl0SurMeta?.path ?? "/dashboard/integration/create",
    meta: createTCKzzl0SurMeta || {},
    alias: createTCKzzl0SurMeta?.alias || [],
    redirect: createTCKzzl0SurMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/integration/create.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OtfMw68GuRMeta?.name ?? "dashboard-integration-detail-slug",
    path: _91slug_93OtfMw68GuRMeta?.path ?? "/dashboard/integration/detail/:slug()",
    meta: _91slug_93OtfMw68GuRMeta || {},
    alias: _91slug_93OtfMw68GuRMeta?.alias || [],
    redirect: _91slug_93OtfMw68GuRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/integration/detail/[slug].vue").then(m => m.default || m)
  },
  {
    name: editObqGbOFVtIMeta?.name ?? "dashboard-integration-edit",
    path: editObqGbOFVtIMeta?.path ?? "/dashboard/integration/edit",
    meta: editObqGbOFVtIMeta || {},
    alias: editObqGbOFVtIMeta?.alias || [],
    redirect: editObqGbOFVtIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/integration/edit.vue").then(m => m.default || m)
  },
  {
    name: funnel_45smsaYhSscMSe7Meta?.name ?? "dashboard-integration-funnel-sms",
    path: funnel_45smsaYhSscMSe7Meta?.path ?? "/dashboard/integration/funnel-sms",
    meta: funnel_45smsaYhSscMSe7Meta || {},
    alias: funnel_45smsaYhSscMSe7Meta?.alias || [],
    redirect: funnel_45smsaYhSscMSe7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/integration/funnel-sms.vue").then(m => m.default || m)
  },
  {
    name: meu_45planoNnmkrN4ENCMeta?.name ?? "dashboard-meu-plano",
    path: meu_45planoNnmkrN4ENCMeta?.path ?? "/dashboard/meu-plano",
    meta: meu_45planoNnmkrN4ENCMeta || {},
    alias: meu_45planoNnmkrN4ENCMeta?.alias || [],
    redirect: meu_45planoNnmkrN4ENCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/meu-plano.vue").then(m => m.default || m)
  },
  {
    name: FORMATARPlG9PiSpnZMeta?.name ?? "dashboard-modulos-FORMATAR",
    path: FORMATARPlG9PiSpnZMeta?.path ?? "/dashboard/modulos/FORMATAR",
    meta: FORMATARPlG9PiSpnZMeta || {},
    alias: FORMATARPlG9PiSpnZMeta?.alias || [],
    redirect: FORMATARPlG9PiSpnZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/FORMATAR.vue").then(m => m.default || m)
  },
  {
    name: gerador_45link_45detalhekPGbJa1qt7Meta?.name ?? "dashboard-modulos-gerador-link-detalhe",
    path: gerador_45link_45detalhekPGbJa1qt7Meta?.path ?? "/dashboard/modulos/gerador-link-detalhe",
    meta: gerador_45link_45detalhekPGbJa1qt7Meta || {},
    alias: gerador_45link_45detalhekPGbJa1qt7Meta?.alias || [],
    redirect: gerador_45link_45detalhekPGbJa1qt7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/gerador-link-detalhe.vue").then(m => m.default || m)
  },
  {
    name: JOGADORnwjSDUUv4RMeta?.name ?? "dashboard-modulos-JOGADOR",
    path: JOGADORnwjSDUUv4RMeta?.path ?? "/dashboard/modulos/JOGADOR",
    meta: JOGADORnwjSDUUv4RMeta || {},
    alias: JOGADORnwjSDUUv4RMeta?.alias || [],
    redirect: JOGADORnwjSDUUv4RMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/JOGADOR.vue").then(m => m.default || m)
  },
  {
    name: LINKt5VywjcHvZMeta?.name ?? "dashboard-modulos-LINK",
    path: LINKt5VywjcHvZMeta?.path ?? "/dashboard/modulos/LINK",
    meta: LINKt5VywjcHvZMeta || {},
    alias: LINKt5VywjcHvZMeta?.alias || [],
    redirect: LINKt5VywjcHvZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/LINK.vue").then(m => m.default || m)
  },
  {
    path: criar_45smsLVbix5xfUBMeta?.path ?? "/dashboard/modulos/SMS/criar-sms",
    children: [
  {
    name: configuracaowhi0IvVf3sMeta?.name ?? "dashboard-modulos-SMS-criar-sms-configuracao",
    path: configuracaowhi0IvVf3sMeta?.path ?? "configuracao",
    meta: configuracaowhi0IvVf3sMeta || {},
    alias: configuracaowhi0IvVf3sMeta?.alias || [],
    redirect: configuracaowhi0IvVf3sMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/configuracao.vue").then(m => m.default || m)
  },
  {
    name: indexa0fYE4Uc8sMeta?.name ?? "dashboard-modulos-SMS-criar-sms",
    path: indexa0fYE4Uc8sMeta?.path ?? "",
    meta: indexa0fYE4Uc8sMeta || {},
    alias: indexa0fYE4Uc8sMeta?.alias || [],
    redirect: indexa0fYE4Uc8sMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/index.vue").then(m => m.default || m)
  },
  {
    name: revisargaoqBOyrK3Meta?.name ?? "dashboard-modulos-SMS-criar-sms-revisar",
    path: revisargaoqBOyrK3Meta?.path ?? "revisar",
    meta: revisargaoqBOyrK3Meta || {},
    alias: revisargaoqBOyrK3Meta?.alias || [],
    redirect: revisargaoqBOyrK3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms/revisar.vue").then(m => m.default || m)
  }
],
    name: criar_45smsLVbix5xfUBMeta?.name ?? undefined,
    meta: criar_45smsLVbix5xfUBMeta || {},
    alias: criar_45smsLVbix5xfUBMeta?.alias || [],
    redirect: criar_45smsLVbix5xfUBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/criar-sms.vue").then(m => m.default || m)
  },
  {
    name: indexAF6lBzu7UfMeta?.name ?? "dashboard-modulos-SMS",
    path: indexAF6lBzu7UfMeta?.path ?? "/dashboard/modulos/SMS",
    meta: indexAF6lBzu7UfMeta || {},
    alias: indexAF6lBzu7UfMeta?.alias || [],
    redirect: indexAF6lBzu7UfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JBvMZt4VmuMeta?.name ?? "dashboard-modulos-SMS-relatorio-id",
    path: _91id_93JBvMZt4VmuMeta?.path ?? "/dashboard/modulos/SMS/relatorio/:id()",
    meta: _91id_93JBvMZt4VmuMeta || {},
    alias: _91id_93JBvMZt4VmuMeta?.alias || [],
    redirect: _91id_93JBvMZt4VmuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/SMS/relatorio/[id].vue").then(m => m.default || m)
  },
  {
    name: VALIDADORe86XI3zhplMeta?.name ?? "dashboard-modulos-VALIDADOR",
    path: VALIDADORe86XI3zhplMeta?.path ?? "/dashboard/modulos/VALIDADOR",
    meta: VALIDADORe86XI3zhplMeta || {},
    alias: VALIDADORe86XI3zhplMeta?.alias || [],
    redirect: VALIDADORe86XI3zhplMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/modulos/VALIDADOR.vue").then(m => m.default || m)
  },
  {
    path: configuracoesOO5mAmMqZgMeta?.path ?? "/dashboard/perfil/configuracoes",
    children: [
  {
    name: indexbjHvUPX1UNMeta?.name ?? "dashboard-perfil-configuracoes",
    path: indexbjHvUPX1UNMeta?.path ?? "",
    meta: indexbjHvUPX1UNMeta || {},
    alias: indexbjHvUPX1UNMeta?.alias || [],
    redirect: indexbjHvUPX1UNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/perfil/configuracoes/index.vue").then(m => m.default || m)
  }
],
    name: configuracoesOO5mAmMqZgMeta?.name ?? undefined,
    meta: configuracoesOO5mAmMqZgMeta || {},
    alias: configuracoesOO5mAmMqZgMeta?.alias || [],
    redirect: configuracoesOO5mAmMqZgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/perfil/configuracoes.vue").then(m => m.default || m)
  },
  {
    name: plano_45inativouOC12fluBDMeta?.name ?? "dashboard-plano-inativo",
    path: plano_45inativouOC12fluBDMeta?.path ?? "/dashboard/plano-inativo",
    meta: plano_45inativouOC12fluBDMeta || {},
    alias: plano_45inativouOC12fluBDMeta?.alias || [],
    redirect: plano_45inativouOC12fluBDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/plano-inativo.vue").then(m => m.default || m)
  },
  {
    name: sairvobEaFEdqBMeta?.name ?? "dashboard-sair",
    path: sairvobEaFEdqBMeta?.path ?? "/dashboard/sair",
    meta: sairvobEaFEdqBMeta || {},
    alias: sairvobEaFEdqBMeta?.alias || [],
    redirect: sairvobEaFEdqBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/sair.vue").then(m => m.default || m)
  },
  {
    name: abrirycg3xRfAgyMeta?.name ?? "dashboard-ticket-abrir",
    path: abrirycg3xRfAgyMeta?.path ?? "/dashboard/ticket/abrir",
    meta: abrirycg3xRfAgyMeta || {},
    alias: abrirycg3xRfAgyMeta?.alias || [],
    redirect: abrirycg3xRfAgyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/ticket/abrir.vue").then(m => m.default || m)
  },
  {
    name: index6N8yvCkOrMMeta?.name ?? "dashboard-ticket",
    path: index6N8yvCkOrMMeta?.path ?? "/dashboard/ticket",
    meta: index6N8yvCkOrMMeta || {},
    alias: index6N8yvCkOrMMeta?.alias || [],
    redirect: index6N8yvCkOrMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/ticket/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_935Pg5sXJpELMeta?.name ?? "dashboard-ticket-visualizar-id",
    path: _91_46_46_46id_935Pg5sXJpELMeta?.path ?? "/dashboard/ticket/visualizar/:id(.*)*",
    meta: _91_46_46_46id_935Pg5sXJpELMeta || {},
    alias: _91_46_46_46id_935Pg5sXJpELMeta?.alias || [],
    redirect: _91_46_46_46id_935Pg5sXJpELMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard/ticket/visualizar/[...id].vue").then(m => m.default || m)
  },
  {
    name: first_45access3B5cwQVg3AMeta?.name ?? "first-access",
    path: first_45access3B5cwQVg3AMeta?.path ?? "/first-access",
    meta: first_45access3B5cwQVg3AMeta || {},
    alias: first_45access3B5cwQVg3AMeta?.alias || [],
    redirect: first_45access3B5cwQVg3AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/first-access.vue").then(m => m.default || m)
  },
  {
    name: indexJX2dp37VJQMeta?.name ?? "index",
    path: indexJX2dp37VJQMeta?.path ?? "/",
    meta: indexJX2dp37VJQMeta || {},
    alias: indexJX2dp37VJQMeta?.alias || [],
    redirect: indexJX2dp37VJQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: register_45lZJwqOUkJaZMeta?.name ?? "register-l",
    path: register_45lZJwqOUkJaZMeta?.path ?? "/register-l",
    meta: register_45lZJwqOUkJaZMeta || {},
    alias: register_45lZJwqOUkJaZMeta?.alias || [],
    redirect: register_45lZJwqOUkJaZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/register-l.vue").then(m => m.default || m)
  },
  {
    path: registerrhQHCmO9O7Meta?.path ?? "/register",
    children: [
  {
    name: indexcWTEcKxExrMeta?.name ?? "register",
    path: indexcWTEcKxExrMeta?.path ?? "",
    meta: indexcWTEcKxExrMeta || {},
    alias: indexcWTEcKxExrMeta?.alias || [],
    redirect: indexcWTEcKxExrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: reviewErBuL7Yj0FMeta?.name ?? "register-review",
    path: reviewErBuL7Yj0FMeta?.path ?? "review",
    meta: reviewErBuL7Yj0FMeta || {},
    alias: reviewErBuL7Yj0FMeta?.alias || [],
    redirect: reviewErBuL7Yj0FMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/register/review.vue").then(m => m.default || m)
  },
  {
    name: roleLxmrQ3JfWxMeta?.name ?? "register-role",
    path: roleLxmrQ3JfWxMeta?.path ?? "role",
    meta: roleLxmrQ3JfWxMeta || {},
    alias: roleLxmrQ3JfWxMeta?.alias || [],
    redirect: roleLxmrQ3JfWxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/register/role.vue").then(m => m.default || m)
  }
],
    name: registerrhQHCmO9O7Meta?.name ?? undefined,
    meta: registerrhQHCmO9O7Meta || {},
    alias: registerrhQHCmO9O7Meta?.alias || [],
    redirect: registerrhQHCmO9O7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: smsAsMjt3uJqMMeta?.name ?? "sms",
    path: smsAsMjt3uJqMMeta?.path ?? "/sms",
    meta: smsAsMjt3uJqMMeta || {},
    alias: smsAsMjt3uJqMMeta?.alias || [],
    redirect: smsAsMjt3uJqMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sms.vue").then(m => m.default || m)
  }
]