
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "b5b49229-e6e6-465c-a1d1-2425a02d4caa"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/.app/app.config.ts"
import cfg1 from "/vercel/path0/layers/tairo/app.config.ts"
import cfg2 from "/vercel/path0/node_modules/.pnpm/@shuriken-ui+nuxt@2.0.1_nuxt@3.9.3_vue@3.4.35/node_modules/@shuriken-ui/nuxt/app.config.ts"
import cfg3 from "/vercel/path0/layers/tairo-layout-collapse/app.config.ts"
import cfg4 from "/vercel/path0/layers/tairo-layout-iconnav/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, inlineConfig)
